<button mat-icon-button
        [color]="hasValue ? 'primary' : undefined"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
        (menuOpened)="onMenuOpen()">
  <mat-icon>filter_list</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="filter-menu" (click)="$event.stopPropagation()">
    <div>Filter by {{ column.name }}</div>
    <div [ngSwitch]="column.type">
      <div *ngSwitchCase="'text'">
        <app-boomer-text-input [label]="column.name" [form]="form" formControlName="value"
                               #firstInput></app-boomer-text-input>
      </div>
      <div *ngSwitchCase="'number'">
        <app-boomer-number-input [label]="column.name" [form]="form" formControlName="value"
                                 #firstInput></app-boomer-number-input>
      </div>
      <ng-container *ngSwitchCase="'date'">
        <app-boomer-date-input label="From" [form]="form" formControlName="from" #firstInput></app-boomer-date-input>
        <app-boomer-date-input label="To" [form]="form" formControlName="to"></app-boomer-date-input>
      </ng-container>
      <ng-container *ngSwitchCase="'exact_datetime'">
        <app-boomer-date-input label="From" [form]="form" formControlName="from" #firstInput></app-boomer-date-input>
        <app-boomer-date-input label="To" [form]="form" formControlName="to"></app-boomer-date-input>
      </ng-container>
      <ng-container *ngSwitchCase="'select'" class="flex flex-col">
        <mat-radio-group class="flex flex-col mb-4" formControlName="value">
          <mat-radio-button [value]="option.value" *ngFor="let option of column['options']" #firstInput>
            {{ option.label }}
          </mat-radio-button>
        </mat-radio-group>
      </ng-container>
      <ng-container *ngSwitchCase="'multi-select'" class="flex flex-col">
        <mat-radio-group class="flex flex-col mb-4" formControlName="value">
          <mat-checkbox [formControlName]="option.value" *ngFor="let option of column['options']">
            {{ option.label }}
          </mat-checkbox>
        </mat-radio-group>
      </ng-container>
      <ng-container *ngSwitchCase="'agent'">
        <app-boomer-agent-field label="Agent"
                                [form]="form"
                                formControlName="value">
        </app-boomer-agent-field>
      </ng-container>
      <ng-container *ngSwitchCase="'boomer_user'">
        <app-boomer-boomer-user-field label="Boomer User"
                                      [form]="form"
                                      formControlName="value">
        </app-boomer-boomer-user-field>
      </ng-container>
      <ng-container *ngSwitchDefault>
        Unsupported filter type: {{ column.type }}
      </ng-container>
    </div>
    <div class="flex flex-row justify-between gap-x-8">
      <button type="reset" mat-raised-button (click)="clear()">
        Clear
      </button>

      <button type="submit" mat-raised-button color="primary">
        Search
      </button>
    </div>
  </form>
</mat-menu>
